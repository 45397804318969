import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider
import ReactGA from 'react-ga4'
import Sentry from 'gatsby-plugin-sentry'

try {
  ReactGA.initialize([{ trackingId: 'G-8E95WP9PQT' }])
} catch (err) {
  Sentry.captureException(error)
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(`This application has been updated. ` + `Reload to display the latest version?`)
  if (answer === true) {
    window.location.reload()
  }
}
